.root {
  margin-top: 10px;
}

.content {
  padding: 10px;
  display: flex;
}

.formControl {
  width: 100%;
}

.iconButton {
  padding: 20px;
  height: fit-content;
}