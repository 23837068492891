.cardIn {

  .title {
    font-size: 12px;
    display: inline-block;
  }

  .content {
    background: #FFF9C4;
    padding: 10px;
  }

  .content:last-child {
    padding: 10px;
  }
}

.cardOut {

  .title {
    font-size: 12px;
    display: inline-block;
  }

  .content {
    padding: 10px;
  }

  .content:last-child {
    padding: 10px;
  }
}

.unread {
  height: 7px;
  width: 7px;
  background: lightblue;
  display: inline-block;
  margin: 1px 5px 1px 0;
  border: blue 1px solid;
  border-radius: 5px;
}

.sender {
  margin-left: 10px;
  font-style: italic;
}