.root {
  padding: 2px 4px;
  display: flex;
  align-content: center;
}

.input {
  textarea {
    resize: vertical;
    min-height: 100px;
  }
}

.iconButton {
  padding: 20px;
  height: fit-content;
}