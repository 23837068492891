.img {
  text-align: center;
  height: 100%;

  img {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 40%;
  }
}

.link {
  text-align: center;
  font-size: 26px;
  margin-top: 20px;

  a {
    text-decoration: underline;
  }
}