.loadingPanel {
  text-align: center;
  height: 100%;

  img {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 40%;
  }
}